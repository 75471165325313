import React, { Component } from 'react';
import '../../assets/css/base/section8.css';
import news1 from '../../assets/img/moditt-new-img-1@3x.jpg';
import news2 from '../../assets/img/moditt-new-img-2@3x.png';
import link from '../../assets/img/moditt-ic-web-link-b@3x.png';
import '../../assets/css/mobile/mobile_320.css';
import '../../assets/css/tablet/tablet.css';



class Section8 extends Component {
    render() {
        return (
            <div className="size">
                <div className="section8">
                     
                     <div className="section-contents">
                     <div className="contents-text">
                     <div className="contents-contents">     
                     <div className="contents-base">
                     <h1>NEWS. </h1>
                     </div>
                     </div>
                     </div>
                    
                    {/* base */}

                <div className="news-img-base">
                    <div className="news-img">
                    <img src={news1} alt="news1" className="news"/>
                    <div className="news-text">
                    <h1>모딧, 신규 서비스 '토샤' 출시</h1>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=17255772&memberNo=27561022&vType=VERTICAL">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                    <h2>타로 상담 서비스 ‘타로문’을 운영중인 모딧이 타로 챗봇 서비스인 토샤를 안드로이드 및 iOS에 정식출시했다고 5일 밝혔다. </h2>
                    <div className="news-link-desktop">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=17255772&memberNo=27561022&vType=VERTICAL">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>

                    <div className="news-img">
                    <img src={news2} alt="news2" className="news"/>
                    <div className="news-text">
                    <h1>인터마인드, '모딧'으로 사명 변경</h1>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=16955516&memberNo=27561022">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                    <h2>인터마인드(대표 권영국)은 사명을 ‘모딧’으로 변경하기로 했다고 밝혔다. ‘모딧’은 기존 플랫폼 서비스 전문기업으로 온라인/모바일 심리상담과 타로상담 서비스를 제공해왔다. </h2>
                    <div className="news-link-desktop">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=16955516&memberNo=27561022">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>
                </div>

                <div className="news-img-tablet">
                    <div className="news-img">
                    <img src={news1} alt="news1" className="news"/>
                    <div className="news-text">
                    <h1>모딧, 신규 서비스 '토샤' 출시</h1>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=17255772&memberNo=27561022&vType=VERTICAL">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                    <h2>타로 상담 서비스 ‘타로문’을 운영중인 모딧이 타로 챗봇 서비스인 토샤를 안드로이드 및 iOS에 정식출시했다고 5일 밝혔다. </h2>
                    <div className="news-link-desktop">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=17255772&memberNo=27561022&vType=VERTICAL">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>

                    <div className="news-img">
                    <img src={news2} alt="news2" className="news"/>
                    <div className="news-text">
                    <h1>인터마인드, '모딧'으로 사명 변경</h1>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=16955516&memberNo=27561022">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                    <h2>인터마인드(대표 권영국)은 사명을 ‘모딧’으로 변경하기로 했다고 밝혔다. ‘모딧’은 기존 플랫폼 서비스 전문기업으로 온라인/모바일 심리상담과 타로상담 서비스를 제공해왔다. </h2>
                    <div className="news-link-desktop">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=16955516&memberNo=27561022">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>
                </div>

                            {/* 모바일 version. */}

                <div className="news-img-mobile">
                    <div className="news-img">
                    <img src={news1} alt="news1" className="news"/>
                    <div className="news-text">
                    <h1>모딧, 신규 서비스 '토샤' 출시</h1>                 
                    <h2>타로 상담 서비스 ‘타로문’을 운영중인 모딧이 타로 챗봇 서비스인 토샤를 안드로이드 및 iOS에 정식출시했다고 5일 밝혔다. </h2>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=17255772&memberNo=27561022&vType=VERTICAL">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>

                    <div className="news-img">
                    <img src={news2} alt="news2" className="news"/>
                    <div className="news-text">
                    <h1>인터마인드, '모딧'으로 사명 변경</h1>                  
                    <h2>인터마인드(대표 권영국)은 사명을 ‘모딧’으로 변경하기로 했다고 밝혔다. ‘모딧’은 기존 플랫폼 서비스 전문기업으로 온라인/모바일 심리상담과 타로상담 서비스를 제공해왔다. </h2>
                    <div className="news-link-tablet">
                    <a href="https://m.post.naver.com/viewer/postView.nhn?volumeNo=16955516&memberNo=27561022">                          
                    <h3>기사 보러가기</h3>
                    <img src={link} alt="link" className="news-web"/>
                    </a>
                    </div>
                </div>
                    </div>

                </div>
     
                     </div>
                     </div>


            </div>
        );
    }
}

export default Section8;