import React, { Component } from 'react';
import '../../assets/css/base/section2.css';
import '../../assets/css/desktop/desktop.css';
import '../../assets/css/tablet/tablet.css';
import '../../assets/css/mobile/mobile_320.css';



class Section2 extends Component {
    render() {
        return (
            <div className="size">
                <div className="section2">
           
                </div>
            </div>
        );
    }
}

export default Section2;