import React, { Component } from 'react';
import '../../assets/css/base/section7.css';
import '../../assets/css/mobile/mobile_320.css';
import '../../assets/css/tablet/tablet.css';


class Section7 extends Component {
    render() {
        return (
            <div className="size">
            <div className="section7">

                             
            <div className="section-contents">
            <div className="contents-left">
            <div className="contents-text">
            <div className="b-contents-text">
            <div className="team-line-top-desktop"></div>
            <div className="team-line-top-career"></div>
            <h1>CAREER </h1>              
            </div> 
            </div>
            </div>


            <div className="contents-right">
            <div className="b-contents-text-no-title">
            <br></br>
            <h3>
            라이프 스타일을 보다 더 편리하게 바꾸기를 원하며 트렌드와 사용자 니즈에 
            민감하게 반응하고 빠르게 생각하여 실천하는 사람들과 함께하길 원합니다. 
            라이프 스타일의 변화를 함께 만들고 싶다면 모딧에 지원해보세요.
            </h3>
            <a href="https://www.rocketpunch.com/companies/moditt/jobs">                           
            <div className="join">
            <h1>모딧에 지원하기</h1>            
            </div>
            </a>
            </div>
            </div>



            </div>
            </div>                
            </div>
        );
    }
}

export default Section7;