import React, { Component } from 'react';
import '../../assets/css/base/section9.css'
import '../../assets/css/mobile/mobile_320.css';
import '../../assets/css/tablet/tablet.css';



class Section9 extends Component {
    render() {
        return (
            <div div className="size">
                <div className="section9">

                <div className="section-contents">
                <div className="facebook-moditt">
                     <h1>모딧의 생생한 소식 보러가기! </h1>

                <div className="facebook-moditt-mobile">
            <a href="https://www.facebook.com/%EB%AA%A8%EB%94%A7-114625625829191/">                          
             <div className="facebook">
                <h1>페이스북 바로가기</h1>
                </div>
            </a>
                </div>
                     </div>
        <div className="facebook-moditt-base">
           <div className="facebook-moditt">
            <a href="https://www.facebook.com/%EB%AA%A8%EB%94%A7-114625625829191/">                          
             <div className="facebook">
                <h1>페이스북 바로가기</h1>
                </div>
            </a>
                </div>
                </div>

                </div>

                </div>

            </div>
        );
    }
}

export default Section9;