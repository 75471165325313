import React, { Component } from 'react';
import '../../assets/css/base/section6.css';
import img1 from '../../assets/img/moditt-team-img-work@3x.jpg';
import img2 from '../../assets/img/moditt-team-img-flexibility@3x.jpg';
import img3 from '../../assets/img/moditt-team-img-communication@3x.jpg';
import img4 from '../../assets/img/moditt-moditt-img@3x.jpg';
import comment1 from '../../assets/img/comment-img-1@3x.png';
import comment2 from '../../assets/img/comment-img-2@3x.png';
import comment21 from '../../assets/img/comment-img-2-1@3x.png';
import comment3 from '../../assets/img/comment-img-3@3x.png';
import '../../assets/css/mobile/mobile_320.css';
import '../../assets/css/tablet/tablet.css';



class Section6 extends Component {
    render() {
        return (
            <div className="size">
                <div className="section6">
                
                {/* 이미지 세개 */}
                <div className="section6_top">                        
                <div className="section-contents">
                <div className="three-img">
                <img src={img1} alt="img1" className="moditt-work"/>
                <div className="moditt-work-text">
                <h1>WORK AND LIFE BALANCE.</h1>
                <h2>개인의 삶과 업무 성취의 조화를 통해 삶의 질 향상을 추구합니다. </h2>
                </div>
                </div>
                <div className="three-img">
                <img src={img2} alt="img2" className="moditt-work"/>
                <div className="moditt-work-text">
                <h1>FLEXIBILITY.</h1>
                <h2>참신한 아이디어, 새로운 기준, 숨겨진 니즈를 수용할 수 있는 유연성을 중요시합니다. </h2>
                </div>
                </div>
                <div className="three-img">
                <img src={img3} alt="img3" className="moditt-work"/>
                <div className="moditt-work-text">
                <h1>COMMUNICATION.</h1>
                <h2>구성원 간의 존중과 배려를 바탕으로 원활한 소통과 끈끈한 협력을 해나갑니다. </h2>
                </div>              
                </div> 
                <img src={img4} alt="img4" className="moditt-img"/>              
                </div>                
               
                </div>

                <div className="section6_bottom">
                <img src={img4} alt="img4" className="moditt-img-tablet"/> 
                <div className="section-contents">
                <div className="w-contents-left2">
                <div className="w-contents-text">
                <div className="team-line-top"> -</div>
                <h1>INTERVIEW </h1>              
                </div>                
                </div>
                <div className="interview">
                <div className="box">
                <img src={comment1} alt="comment1" className="comment"/> 
                <h1> DESIGNER 'OH' </h1>
                <h2>"유연하고 수평적인 조직문화로인해 팀 동료들과 즐거운 분위기에서 업무를 
                    진행 할 수 있습니다. 자유롭게 아이디어 제안 및 반영이 가능합니다."</h2>                
                </div>
                </div>

                <div className="interview-right" style={{float:"right"}}>
                <div className="box2">
                <img src={comment21} alt="comment1" className="comment21"/> 
                <img src={comment2} alt="comment1" className="comment2"/> 
                <h1 className="ham"> MARKETER 'HAM' </h1>
                <h2 className="ham-h2">"업무 스펙트럼을 넓히며 개인의 성장을 할 수 있음과 동시에 워라벨을 
                     지켜주는 회사입니다. 주 39시간 근무가 대표적입니다. "</h2>                
                </div>
                </div>

                <div className="interview">
                <div className="box">
                <img src={comment3} alt="comment1" className="comment"/> 
                <h1> DEVELOPER 'KIM' </h1>
                <h2>"자율적인 분위기에서 업무를 진행하며 배려와 커뮤니케이션이 공존하는 곳 입니다. 
                     이와 함께 다양한 프로젝트를 경험할 수 있습니다."</h2>                
                </div>
                </div>
           



                </div>
                </div>
                </div>
                
            </div>
        );
    }
}

export default Section6;