import React, { Component } from 'react';
import '../../assets/css/base/section4.css';
import tocha from '../../assets/img/prof-tocha@3x.png';
import tarotmoon from '../../assets/img/icon.png';
import maum from '../../assets/img/prof-maum@3x.png';
import munse from '../../assets/img/prof-munse@3x.png';
import google from '../../assets/img/moditt-ic-google-play-store@3x.png';
import apple from '../../assets/img/moditt-ic-app-store@3x.png'
import link from '../../assets/img/moditt-ic-web-link-green@3x.png'
import '../../assets/css/tablet/tablet.css';
import '../../assets/css/mobile/mobile_320.css';



class Section4 extends Component {
    render() {
        return (
            <div className="size">
                <div className="section4">
                    <div className="section-contents">

                        <div className="desktop">
                        <div className="contents-left">
                            <div className="contents-top">
                                <div className="contents-app">
                                <img src={tocha} alt="tocha" className="app-icon"/>
                                 </div>
                                 <div className="app-description">
                                    <h1> 토샤 </h1>
                                    <h2>인공지능 타로 챗봇. 사막여우 토샤가 채팅 방식으로 <br></br>
                                        다양한 타로를 봐드려요.</h2>
                                    <div className="go-to-web">
                                    <a href="http://tocha.kr"><h3> 웹사이트 바로가기 </h3></a>
                                    <img src={link} alt="link" className="web"/>
                                    </div>
                                
                                    <div className="google-apple">
                                    <div className="app">
                                    <a href="https://play.google.com/store/apps/details?id=com.intermindco.maumbot&showAllReviews=true">
                                    <div className="google">
                                    <img src={google} alt="google" className="Media-play-icon"></img>
                                    <h1> Google PlayStore </h1>
                                    </div>
                                    </a>
                                    </div>
                                    <div className="app">
                                    <a href="https://itunes.apple.com/app/id1441749378">
                                    <div className="apple">                                
                                    <img src={apple} alt="apple" className="Media-play-icon"></img>
                                    <h1> Apple AppStore </h1>
                                    </div>
                                    </a>
                                    </div>
                                    </div>
                                 </div>
                            </div>


                            <div className="contents-bottom">
                            <div className="contents-app-bottom">
                            <img src={maum} alt="tocha" className="app-icon"/>
                            </div>
                            <div className="app-description-bottom">
                                 <h1>마음치유연구소</h1>
                                 <h2>국내 최초 온라인 심리상담센터, 검증된 심리상담사에게 <br></br>
                                     원격으로 전문적인 상담을 받을 수 있어요.</h2>
                             <div className="go-to-web">
                                <a href="https://maumlab.com/"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                             </div>
                             
                             <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.maumlab.hybrid">
                                <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                
                                </div>
                                 </div>
                            </div>
                        </div>

                        <div className="contents-right">

                            <div className="contents-top">
                            <div className="contents-app">                           
                            <img src={tarotmoon} alt="tocha" className="app-icon"/>
                            </div>
                             <div className="app-description">
                                 <h1> 타로문 </h1>
                                 <h2>국내 최초 타로 상담 앱, 유명한 타로마스터들과 <br></br>
                                     언제든 실시간으로 1:1 상담을 할 수 있어요.</h2>
                                <div className="go-to-web">
                                <a href="https://tarotmoon.co.kr/"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                                </div>
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=kr.co.tarotmoon.app">
                                <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app">
                                <a href="https://itunes.apple.com/app/id1250808582">
                                <div className="apple">                                
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div className="contents-bottom">
                            <div className="contents-app-bottom">
                            <img src={munse} alt="tocha" className="app-icon"/>
                             </div>
                             <div className="app-description-bottom">
                                 <h1>명운세</h1>
                                 <h2>실시간 운세상담 앱, 전국에서 활동 중인 역학, 타로, 신점<br></br>
                                     전문가들과 부담 없이 온라인으로 만날 수 있어요.</h2>
                             <div className="go-to-web">
                                <a href="https://www.munse.co.kr/"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                             </div>

                                 <div className="google-apple">
                                     <div className="app">
                                         <a href="https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload">
                                             <div className="google">
                                                 <img src={google} alt="google" className="Media-play-icon"></img>
                                                 <h1> Google PlayStore </h1>
                                             </div>
                                         </a>
                                     </div>
                                     <div className="app">
                                         <a href="https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842">
                                             <div className="apple">
                                                 <img src={apple} alt="apple" className="Media-play-icon"></img>
                                                 <h1> Apple AppStore </h1>
                                             </div>
                                         </a>
                                     </div>
                                 </div>
                    
                                 </div>
                            </div>
                        </div>
                        </div>


                    <div className="app-tablet">
                        <div className="app-tablet-section">
                        <div className="contents-app">  
                        <img src={tocha} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                 <h1> 토샤 </h1>
                                 <div className="go-to-web">
                                <a href="http://tocha.kr"><h3> 웹사이트 바로가기 </h3>
                                <img src={link} alt="link" className="web"/></a>
                                </div>
                                 <h2>인공지능 타로 챗봇. 사막여우 토샤가 채팅 방식으로 다양한 타로를 봐드려요.</h2>
                                 
                               
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.intermindco.maumbot&showAllReviews=true">
                                <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app">
                                <a href="https://itunes.apple.com/app/id1441749378">
                                <div className="apple">                                
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                                </div> 
                               
                        </div>                
                        <div className="app-tablet-section">
                        <div className="contents-app">  
                        <img src={tarotmoon} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                <h1> 타로문 </h1>
                                 <div className="go-to-web">
                                 <a href="https://tarotmoon.co.kr/"><h3> 웹사이트 바로가기 </h3>
                                <img src={link} alt="link" className="web"/></a>
                                </div>
                                <h2>국내 최초 타로 상담 앱, 유명한 타로마스터들과 언제든 실시간으로 1:1 상담을 할 수 있어요.</h2>
                                 
                               
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=kr.co.tarotmoon.app">
                               <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app">
                                <a href="https://itunes.apple.com/app/id1250808582">
                               <div className="apple">                                
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                                </div> 
                               
                        </div>
                        <div className="app-tablet-section">
                        <div className="contents-app">  
                        <img src={maum} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                 <h1>마음치유연구소</h1>
                                 <div className="go-to-web">
                                 <a href="https://maumlab.com/"><h3> 웹사이트 바로가기 </h3>
                                 <img src={link} alt="link" className="web"/></a>
                                </div>
                                <h2>국내 최초 온라인 심리상담센터, 검증된 심리상담사에게 원격으로 전문적인 상담을 받을 수 있어요.</h2>
                               
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.maumlab.hybrid">
                                 <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"/>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>                                
                                </div>
                                </div> 
                               
                        </div>
                        <div className="app-tablet-section">
                        <div className="contents-app">  
                        <img src={munse} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                <h1>명운세</h1>
                                <a href="https://www.munse.co.kr/">
                                <div className="go-to-web">
                                 <h3> 웹사이트 바로가기 </h3>
                                <img src={link} alt="link" className="web"/>
                                </div></a>
                                <h2>실시간 운세상담 앱, 전국에서 활동 중인 역학, 타로, 신점 전문가들과 부담 없이 온라인으로 만날 수 있어요.</h2>
                               
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload">
                                <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app">
                                <a href="https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842">
                                <div className="apple">
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                                </div> 
                               
                        </div>
                        
                    </div>



                    <div className="app-mobile">
                        <div className="app-mobile-section">
                        <div className="contents-app">  
                        <img src={tocha} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                 <h1> 토샤 </h1>
                               
                                 <h2>인공지능 타로 챗봇. 사막여우 토샤가 채팅 방식으로 다양한 타로를 봐드려요.</h2>
                                 
                                 <div className="go-to-web">
                                <a href="http://tocha.kr"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                                </div>                              
                        </div> 
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.intermindco.maumbot&showAllReviews=true">
                                <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app-2">
                                <a href="https://itunes.apple.com/app/id1441749378">
                                <div className="apple">                                
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                               
                        </div>   


                        <div className="app-mobile-section">
                        <div className="contents-app">  
                        <img src={tarotmoon} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                <h1> 타로문 </h1>
                              
                                <h2>국내 최초 타로 상담 앱, 유명한 타로마스터들과  언제든 실시간으로 1:1 상담을 할 수 있어요.</h2>
                                 
                                 <div className="go-to-web">
                                 <a href="https://tarotmoon.co.kr/"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                                </div>                               
                                </div> 
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=kr.co.tarotmoon.app">
                               <div className="google">
                                <img src={google} alt="google" className="Media-play-icon"></img>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                <div className="app-2">
                                <a href="https://itunes.apple.com/app/id1250808582">
                               <div className="apple">                                
                                <img src={apple} alt="apple" className="Media-play-icon"></img>
                                <h1> Apple AppStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                               
                        </div>
                        <div className="app-mobile-section">
                        <div className="contents-app">  
                        <img src={maum} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                 <h1>마음치유연구소</h1>
                                 
                                <h2>국내 최초 온라인 심리상담센터, 검증된 심리상담사에게 원격으로 전문적인 상담을 받을 수 있어요.</h2>

                                     <div className="go-to-web">
                                 <a href="https://maumlab.com/"><h3> 웹사이트 바로가기 </h3></a>
                                 <img src={link} alt="link" className="web"/>
                                </div>                             
                              
                                </div> 
                                <div className="google-apple">
                                <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.maumlab.hybrid">
                                 <div className="google-maum" style={{width:"280px"}}>
                                <img src={google} alt="google" className="Media-play-icon"/>
                                <h1> Google PlayStore </h1>
                                </div>
                                </a>
                                </div>
                                </div>
                               
                        </div>
                        <div className="app-mobile-section">
                        <div className="contents-app">  
                        <img src={munse} alt="tocha" className="app-icon"/>
                        </div>
                        <div className="app-description">
                                <h1>명운세</h1>
                               
                                <h2>실시간 운세상담 앱, 전국에서 활동 중인 역학, 타로, 신점 전문가들과 부담 없이 온라인으로 만날 수 있어요.</h2>
                               
                                    <div className="go-to-web">
                                 <a href="https://www.munse.co.kr/"><h3> 웹사이트 바로가기 </h3></a>
                                <img src={link} alt="link" className="web"/>
                                </div>
                                </div>

                        </div>
                        <div className="google-apple munse_app">
                            <div className="app">
                                <a href="https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload">
                                    <div className="google">
                                        <img src={google} alt="google" className="Media-play-icon"></img>
                                        <h1> Google PlayStore </h1>
                                    </div>
                                </a>
                            </div>
                            <div className="app-2">
                                <a href="https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842">
                                    <div className="apple">
                                        <img src={apple} alt="apple" className="Media-play-icon"></img>
                                        <h1> Apple AppStore </h1>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>                
            </div>
        );
    }
}

export default Section4;