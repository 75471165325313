import React, { Component } from 'react';
import '../../assets/css/base/header.css'
import moditt from '../../assets/img/moditt-logo-white@3x.png';
import modittlogo from '../../assets/img/moditt-logo-normal@3x.png';
import '../../assets/css/tablet/tablet.css';
import menubar from '../../assets/img/moditt-ic-menu-white@3x.png';
import menubarblack from '../../assets/img/moditt-ic-menu-navy@3x.png';
import '../../assets/css/mobile/mobile_320.css';
import '../../assets/css/mobile/slidebar.css';
import SideBar from './sidebar';
import { Link, animateScroll as scroll } from "react-scroll";
import SideBarFixed from './sidebarFixed';
import $ from "jquery";


  window.addEventListener('scroll', function() {
    var el = document.querySelector('.header-fixed'); 
    
    if(window.scrollY >= 315) 
    el .classList.add('shown');
    else el.classList.remove('shown');
  });

  window.addEventListener('scroll', function() {
    var el = document.querySelector('.header '); 
    
    if(window.scrollY >=315) 
    el .classList.add('shown');
    else el.classList.remove('shown');
  });


//   window.addEventListener('scroll', function() {
//     var el = document.querySelector('.mobile-slidebar '); 
    
//     if(window.scrollY >=620) 
//     el .classList.add('shown');
//     else el.classList.remove('shown');
//   });

  





class Header extends Component {
    render() {
        return (
            <div className="size">
                <div className="all_header"> 
                    <div className="header">
                    <div className="mobile-slidebar">   
                    <SideBar>                        
                        </SideBar>                            
                        </div>  
                    <div className="nav-menu">
                        <a href="#">
                        <img 
                        src={moditt} 
                        className="moditt_logo" 
                        alt="moditt_logo" 
                        onClick={this.scrollToTop}/>
                        </a>                         
                        <img src={menubar} alt="menubar" className="menu-bar"/>
                        </div>
                        <ul className="menu">
                            <li>
                                <Link
                                activeClass="active"
                                to="Section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > ABOUT
                                </Link>

                                <Link
                                activeClass="active"
                                to="Section3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > SERVICES
                                </Link>

                                <Link
                                activeClass="active"
                                to="Section5"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > TEAM
                                </Link>
                                <Link
                                activeClass="active"
                                to="Section8"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > NEWS
                                </Link>

                               
                            </li>
                        </ul>
                    </div>

                    <div className="header-fixed" >                  
                    <div className="nav-menu-fixed">
                        <a href="#">
                        <img 
                        src={modittlogo} 
                        className="moditt_logo_fixed" 
                        alt="moditt_logo" 
                        onClick={this.scrollToTop}/>
                        </a> 
                        
                        {/* <img src={menubarblack} alt="menubarblack" className="menubarblack"/> */}
                        </div>
                        <div className="mobile-slidebar">   
                    <SideBarFixed>                        
                        </SideBarFixed> 
                        </div>  

                        <ul className="menu-fixed">
                            <li>
                                <Link
                                activeClass="active"
                                to="Section1"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > ABOUT
                                </Link>
                                <Link
                                activeClass="active"
                                to="Section3"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > SERVICES
                                </Link>                          
                                <Link
                                activeClass="active"
                                to="Section5"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > TEAM
                                </Link>
                                <Link
                                activeClass="active"
                                to="Section8"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration= {500}
                                onSetActive={this.handleSetActive}
                                className="link"
                                > NEWS
                                </Link>
                           
                            </li>
                        </ul>
                    </div>

                    <div className="header_title">
                    <p className="change_life_style"> CHANGE LIFE STYLE </p>
                    </div>                         
                </div>
            </div>
        );
    }
}

export default Header;