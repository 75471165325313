import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
import Header from "./Header";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Footer from "./Footer";
import { Element } from 'react-scroll';
import Section7 from './Section7';
import Section8 from './Section8';
import Section9 from './Section9';
import '../../assets/css/desktop/desktop.css';



class Index extends Component {
  render() {
    return (
            <div><div className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  
                  <Header/>
                  <Element 
                    name="Section1">
                  <Section1/></Element>
                  <Section2/>
                  <Element 
                    name="Section3">
                  <Section3 /></Element>
                  <Section4 />
                  <Element 
                    name="Section5"><Section5/></Element>                
                  <Section6 />
                  <Section7/>
                  <Element 
                    name="Section8"><Section8/></Element>    
                  <Section9/>
                  <Footer />                     
                </div>
               
              </div>
            </div>
          </div>         
            </div>              
    );
  }
}

export default Index;

