import React, { Component } from 'react';
import '../../assets/css/base/footer.css';

class Footer extends Component {
    render() {
        return (
            <div className="size">
                <div className="footer">

                <div className="section-contents">
                <div className="footer-desktop">
                <div className="w-facebook-moditt">
                     <h1>©Moditt Corporation </h1>
                     <h2>Copyright, Moditt Corp. All rights reserved.</h2>
                     <h3>기업부설연구소 및 벤처 인증 기업</h3>
                     </div>

                <div className="w-facebook-moditt">
                     <h4>Contact : manager@moditt.com</h4>
                     <h5>서울 강남구 논현로98길 28, 3층 306호</h5>
                     </div>
                     </div>
                     <div className="footer-tablet">
                     <div className="w-facebook-moditt">
                     <h1>©Moditt Corporation </h1>
                     <h2>Copyright, Moditt Corp. All rights reserved.</h2>
                     <h3>기업부설연구소 및 벤처 인증 기업</h3>
                     <h2 style={{padding:"40px 0 0 0"}}>Contact : manager@moditt.com</h2>
                     <h5>서울 강남구 논현로98길 28, 3층 306호</h5>
                     </div>
                     </div>
                </div>

                

                </div>
            </div>
        );
    }
}

export default Footer; 