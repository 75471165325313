import React, { Component } from 'react';
import '../../assets/css/base/section1.css';
import '../../assets/css/tablet/tablet.css';
import '../../assets/css/mobile/mobile_320.css';
// import '../../assets/css/mobile/mobile.css';


class Section1 extends Component {
    render() {
        return (
            <div className="size">
            
                <div className="section1">
                     
                <div className="section-contents">
                <div className="contents-left">
                <div className="contents-text">
                <div className="contents-contents">

                <div className="contents-base">
                <h1>ABOUT. </h1>
                </div>
                </div>
                </div>
                </div>


                <div className="contents-right">
                <div className="contents-text-right">
                <h2>사람과 정보와 가치를 모두 잇다.</h2>
                <h3 className="section1-base">
                모딧은 사람들에게 진정한 가치를 전달할 수 있는 플랫폼을 만듭니다.<br></br>
                더 편리하게, 세상은 더 이롭게,<br></br>
                최고의 행복과 최고의 사용자 경험을 제공하는 서비스를 만들길 원합니다.<br></br>
                모딧은 더 넓은 세상에 서비스를 선보이기 위해 매일 도전하고 있습니다.
                </h3>
                <h3 className="section1-mobile">
                모딧은 사람들에게 진정한 가치를 전달할 수 있는 플랫폼을 만듭니다.
                더 편리하게, 세상은 더 이롭게,최고의 행복과 최고의 사용자 경험을 제공하는 서비스를 만들길 원합니다.<br></br>
                모딧은 더 넓은 세상에 서비스를 선보이기 위해 매일 도전하고 있습니다.
                </h3>
                </div>
                </div>



                </div>
                </div>                
            </div>
        );
    }
}

export default Section1;