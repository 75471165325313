import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import {BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
// import Index from "../src/pages/Main/index"; 


ReactDOM.render(
<BrowserRouter> 
<App />
</BrowserRouter>
 
, document.getElementById('root'));

serviceWorker.unregister();

  