import React, { Component } from "react";
import moditt from '../../assets/img/moditt-logo-white@3x.png';
import modittlogo from '../../assets/img/moditt-logo-normal@3x.png';
import '../../assets/css/mobile/slidebar.css';
import '../../assets/css/base.css';
import { Link, animateScroll as scroll } from "react-scroll";
import menubtnblack from '../../assets/img/moditt-ic-menu-navy@3x.png';
import cancel from '../../assets/img/moditt-ic-cancel-navy@3x.png';
import $ from "jquery";


$( document ).ready(function() {

  $( ".cross" ).hide();
  $( ".menulist-fixed" ).hide();
  $( ".menubtn" ).click(function() {
  $( ".menulist-fixed" ).slideToggle( "fast", function() {
  $( ".menubtn" ).hide();
  $( ".cross" ).show();
  });
  });
  
  $( ".cross" ).click(function() {
  $( ".menulist-fixed" ).slideToggle( "fast", function() {
  $( ".cross" ).hide();
  $( ".menubtn" ).show();
  });
  });  
  });


  class SideBarFixed extends Component {

    render(){
        return(           
        <div className="dropmenu">
       <div className="wrap">
        <div className="header ">
        <div className="size solid">
            <nav className="navbar">
            <div className="logo">
            <a href="#"><img src={moditt}
            alt="moditt_logo" 
            className="moditt_logo"
            onClick={this.scrsollToTop}/></a>
            </div>
            <div className="desktop-menu">
        <div>
        <div className="button">           
         <img src={menubtnblack} className="menubtn"/>
         <img src={cancel} className="cross"/>
        </div>
        </div>
        
        <div className="menulist-fixed"> 
        <div className="menulist-fixed-top">
        <img src={modittlogo} className="modittlogo"/>  
        <img src={cancel} className="cross"/>     
        </div> 
        <hr></hr>  
        <ul>
        <li>
        <Link
         activeClass="active"
         to="Section1"
         spy={true}
         smooth={true}
         offset={-70}
         duration= {500}>                                  
        <p className="about">
        ABOUT</p>
       </Link>       
        </li>
        <br></br>
      
        <li>
        <Link
        activeClass="active"
        to="Section3"
        spy={true}
        smooth={true}
        offset={-70}
        duration= {500}>                 
        <p className="services">
        SERVICES</p>
        </Link></li>
        <br></br>

        <li>
        <Link
        activeClass="active"
        to="Section5"
        spy={true}
        smooth={true}
        offset={-70}
        duration= {500}>                 
        <p className="team">
        TEAM</p>
        </Link></li>
        <br></br>

        <li>
        <Link
        activeClass="active"
        to="Section8"
        spy={true}
        smooth={true}
        offset={-70}
        duration= {500}>                 
        <p className="menu-news">
        NEWS</p>
        </Link></li>
        <br></br>


        </ul>
        <div className="bm-overlay">        
        </div>   
        </div>

        </div>
        </nav>
        </div>
        </div>        
        </div>
        </div>
    
)
}
}
export default SideBarFixed;