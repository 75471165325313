import React, { Component } from 'react';
import '../../assets/css/base/section3.css';
import '../../assets/css/tablet/tablet.css';
import '../../assets/css/mobile/mobile_320.css';



class Section3 extends Component {
    render() {
        return (
            <div className="size">
            <div className="section3">
                 
            <div className="section-contents">
            <div className="contents-left">
            <div className="contents-text">
            <div className="contents-contents">

            <div className="contents-base">
            <h1>SERVICES. </h1>
            </div>
            </div>
            </div>
            </div>


            <div className="contents-right">
            <div className="contents-text-no-title">
            <h3>
            모딧은 2016년 서비스 첫 출시 후, 매년 새로운 서비스를 출시하며 250%씩 성장해 왔
            습니다. ARS 전화 위주였던 상담 시장을 모바일 인터넷 전화 상담 시장으로 확장시켰고,
            업계 최저 상담 비용을 통해 고객으로부터 좋은 평가를 받아왔습니다. 
            <br></br><br></br>
            모딧은 마음치유연구소, 타로문 같은 상담 플랫폼 뿐만 아니라 챗봇 서비스인 토샤처럼
            라이프 스타일을 변화시킬 수 있는 서비스를 계속 개발해 나가고 있습니다.
            </h3>
            </div>
            </div>



            </div>
            </div>                
        </div>
        );
    }
}

export default Section3;