import React, { Component } from 'react';
// import { Route } from 'react-router-dom';
// import Header from "./pages/Main/Header";
// import Section1 from "./pages/Main/Section1";
// import Section2 from "./pages/Main/Section2";
// import Section3 from "./pages/Main/Section3";
// import Section4 from "./pages/Main/Section4";
// import Section5 from "./pages/Main/Section5";
// import Section6 from "./pages/Main/Section6";
// import Footer from "./pages/Main/Footer";
import Index from "../src/pages/Main/index"; 



class App extends Component {
  render() {
    return (
     <Index />
   
);         
  }
}

export default App;

