import React, { Component } from 'react';
import '../../assets/css/base/section5.css';
import '../../assets/css/tablet/tablet.css';

import '../../assets/css/mobile/mobile_320.css';


class Section5 extends Component {
    render() {
        return (
            <div className="size">
                <div className="section5">

                <div className="section-contents">
                <div className="w-contents-left">
                <div className="w-contents-text">
                <h1>TEAM. </h1>
                <div className="team-line"> </div>
                <h2>WORK <br></br>
                    TO CHANGE <br></br>
                    LIFESTYLE <br></br>
                </h2>
                </div>
                </div>


                <div className="w-contents-right">
                <div className="w-contents-text-right">
                <h2>논리적이며 팀원의 의견을 존중하는 모딧입니다.</h2>
                <br></br>
                <h3>
                모딧은 문제 해결 과정에서 팀원의 의견을 존중합니다. 작은 의견을 소홀히 생각하지 
                않는 것부터가 더 좋은 해결책을 찾기 위한 시작이기 때문입니다.
                <br></br><br></br>
                모두가 자유롭고 동등한 분위기에서 논리적이고 보다 정확한 해결책을 찾아냅니다. 이를 
                바탕으로 모딧은 라이프 스타일을 점차 변화시켜 나가고  있습니다.  </h3>
                </div>
                </div>



                </div>
                </div>
            </div>
        );
    }
}

export default Section5;